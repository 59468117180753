.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.map-container {
  height: 400px;
  z-index: 1;
}

.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;

}

.marker {
  background-image: url('./Assets/me.jpeg');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.marker-vsce {
  background-image: url('./Assets/vsce.jpeg');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.marker-near {
  background-image: url('./Assets/near.jpeg');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.marker-cal {
  background-image: url('./Assets/cal.jpeg');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.marker-vms {
  background-image: url('./Assets/vms.jpeg');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}


@import url('https://fonts.googleapis.com/css?family=Exo:400,700');

*{
    margin: 0px;
    padding: 0px;
}

/* body{
    font-family: 'Exo', sans-serif;
} */


.context {
    width: 100%;
    position: absolute;
    top:50vh;
    
}

.context h1{
    text-align: center;
    color: #fff;
    font-size: 50px;
}


.area{
    background: #4c6874;  
    /* background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);   */
    width: 100%;
    height: 1100px;
    
   
}

.circles{
    position: absolute;
    top: 400px;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-2000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}

.resume {
  padding-top: 10%;
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  width: 80%;
  margin-left: 7.5%;
  margin-right: 10%;
}

.textBox {
 max-height: 525px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
}
.highcharts-title {
  display: none
}


  @media (max-width: 600px){
    .textBox {
      max-height: 660px;
    }
    .chartCont {
      
    }

  }

  .legend {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 2;
    position: absolute;
    top: 30px;
    left: 0;
    margin: 12px;
    border-radius: 4px;
  
  }

  .email {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 2;
    position: absolute;
    top: 300px;
    left: 0;
    margin: 12px;
    border-radius: 4px;
  
  }
  a:link { color: #b7e9f0; }

  @media (max-width: 600px){
    .legend{
      top: 50px;
    }
    .email{
      top: 350px;
    }
    .circles{
      top: 750px;
    }
    .mapboxgl-ctrl-top-right{
      top: 15%;
    }
    .area{
      height: 150vh;
    }
  }
.glass {
  z-index: 7;
  position: absolute;
  width: 100%;
  height: 110%;
  background-color: rgba(255, 255, 255, .15);  
  backdrop-filter: blur(5px);
}
.modal {
  background-color: rgba(128, 142, 155, 0.9);
  color: #fff;
  padding: 6px 12px;
  padding-bottom: 100px;
  font-family: monospace;
  border-radius: 4px;
  align-self: center;
  width: 80%;
  margin-left: 7.5%;
  margin-right: 10%;
  margin-top: 10%;
}
.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  background-image: url("./Assets/mapboxgl-ctrl-zoom-out.svg");
}

.mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
  background-image: url("./Assets/mapboxgl-ctrl-compass.svg");
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  background-image: url("./Assets/mapboxgl-ctrl-zoom-in.svg");
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
  background-image: url("./Assets/mapboxgl-ctrl-geolocate.svg");
}

.mapboxgl-ctrl-icon{
  padding-right: 30px; 
} 